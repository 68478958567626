import { forwardRef } from "react";

const Card = forwardRef((props, ref) => {
  const {
    children,
    className,
    style
  } = props;

  return (
    <div className={`bg-white rounded p-6 ${className}`} style={style} ref={ref}>
      {children}
    </div>
  )
});

Card.defaultProps = {
  style: {}
}
export default Card;