import React, {useState, useEffect} from "react";
import {Card, Spinner} from "hydrogen";
import moment from "moment";
import ApexCharts from "react-apexcharts";
import firebase from "firebase";
import useUI from "contexts/ui";
import { useFirebaseCollection } from "hydrogen/hooks/firebase";
import { ArrowLeft, ArrowRight } from "react-feather";
const firestore = firebase.firestore;

const TimeManagerWeekGraph = () => {
  const [ui] = useUI();
  const [start] = useState(moment().startOf("week").toDate());
  const [end] = useState(moment().endOf("week").toDate());
  const [slotsData, setSlotsData] = useState([]);

  const [series, setSeries] = useState([10, 15, 29, 20, 10, 6, 15]);

  useEffect(() => {
    const unsubscribe = firestore().collection("users").doc(ui.user.uid).collection("timeManagerSlots")
                                   .where("start", ">=", start).where("start", "<=", end).onSnapshot(snapshot => {
      let _data = [];
      snapshot.forEach(doc => {
        _data.push({uid: doc.id, ...doc.data()});
      });
      setSlotsData(_data);
    }, (error) => {
      console.error(error);
    });
    return unsubscribe;
  }, [start, end]);
  useEffect(() => {
    let _start = moment(start);
    let _end = moment(_start).endOf("day");
    let _slots = [];

    //divide slots by days of week
    for (let i = 0; i < 7; i++) {
      _slots.push(slotsData.filter(i => i.project !== "sleep").filter(i => moment(i.start.toDate()).isBetween(_start, _end)));
      _start.add(1, "day");
      _end.add(1, "day");
    }

    //compute duration
    for (let i = 0; i < 7; i++) {
      let _secondes = 0;
      _slots[i].forEach(slot => _secondes += moment.duration(moment(slot.end.toDate()).diff(moment(slot.start.toDate()))).asSeconds());
      _slots[i] = Math.floor(_secondes / 3600);
    }

    setSeries(_slots);
  }, [slotsData])

  return (
    <Card className="mt-2">
      <div className="flex justify-between w-full mb-2">
        <ArrowLeft  size={32} className="cursor-pointer" color="#26a0fc" />
        <ArrowRight size={32} className="cursor-pointer" color="#26a0fc" />
      </div>
      <div className="flex justify-between w-full mb-4">
        <h4>Rapport de la semaine:</h4>
        <h4>{moment(start).format("Do MMM")} - {moment(end).format("Do MMM")}</h4>
      </div>
      <ApexCharts 
        options={{
          chart: {type: "line",},
          xaxis: {categories: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]},
          stroke: {curve: 'smooth'},
          markers: {size: 6}
        }}
        series={[{
          name: "days",
          data: series
        }]}
        type="line"
        height={350}
      />
    </Card>
  )
}

export default TimeManagerWeekGraph;