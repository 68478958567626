import React, {useState, useEffect} from "react";
import { useFirebaseCollection } from "hydrogen/hooks/firebase";
import {Button, Card, Modal, TextInput, Select} from "hydrogen"
import useUI from "contexts/ui";
import firebase from "firebase";
import { Edit, Plus, Trash } from "react-feather";
import { toast } from "react-toastify";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import { TodoPriority, TodoState } from "enums";
const firestore = firebase.firestore;

const DoubleTodoKanban = (props) => {
  const {
    firestoreRef,
    projectId,
  } = props;

  const history = useHistory();
  const {data, create} = useFirebaseCollection(firestoreRef, []);

  const [projectsRef] = useState(firestore().collection("projects"));
  const {data: projectsData} = useFirebaseCollection(projectsRef, []);
  const [projectsOptions, setProjectsOptions] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [project, setProject] = useState(null);

  useEffect(() => {
    const options = projectsData.filter(p => p.status === "in_progress").map((project) => ({label: project.name, value: project.uid}));
    setProjectsOptions(options);
  }, [projectsData]);

  const _openCreateModal = (p, s) => {
    setIsOpen(true);
    setSelectedPriority(p);
    setSelectedStatus(s);
    setProject(projectId ?? null);
  }
  const _closeCreateModal = () => {
    setIsOpen(false);
    setTitle("");
    setSelectedPriority(null);
    setSelectedStatus(null);
    setProject(projectId ?? null);
  }
  const _onCreateTodo = async () => {
    try {
      await create({
        title,
        createdAt: new Date(),
        updatedAt: new Date(),
        project,
        TodoPriority: selectedPriority.value,
        TodoState: selectedStatus.value,
      })
      _closeCreateModal();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
  const _onMove = async (e) => {
    if (!e.source || !e.destination) return;
    if (e.destination.droppableId === "trash") {
      await firestoreRef.doc(e.draggableId).delete();
    } else {
      await firestoreRef.doc(e.draggableId).update({
        TodoState: e.destination.droppableId.split("-")[0],
        TodoPriority: e.destination.droppableId.split("-")[1]
      });
    }
  }

  return (
    <div>
      <DragDropContext onDragEnd={_onMove}>
        <table className="w-full">
          <thead>
            <tr>
              <th style={{width: 100}}></th>
              {TodoPriority.map(p => <th key={p.value}>{p.label}</th>)}
            </tr>
          </thead>
          <tbody>
            {TodoState.map(s => (
              <React.Fragment key={s.value}>
                <tr className="">
                  <th className="" style={{width: "8%"}}><div className="h-20 flex justify-center items-center">{s.label}</div></th>
                  {TodoPriority.map(p => (
                    <Droppable droppableId={`${s.value}-${p.value}`} direction="vertical" key={p.value}>
                      {(provided, snapshot) => (
                        <td style={{gap: 5, width: "23%"}} className={`p-1 text-white ${p.bgColor2} align-bottom`} ref={provided.innerRef} {...provided.droppableProps}>
                          <div>
                            {data.filter(t => t.TodoState === s.value && t.TodoPriority === p.value).map((t, index) => (
                              <Draggable key={t.uid} draggableId={t.uid} index={index}>
                                {(provided, snapshot) => (
                                  <div className={`px-2 py-1 mb-1 rounded flex justify-between items-center ${p.bgColor1}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <span>
                                      {t.project ? `${(projectsOptions.find(i => i.value === t.project) ?? {}).label} - `: ""}
                                      {t.title}
                                    </span>
                                    <div>
                                      <Edit size={16} className="cursor-pointer" onClick={() => history.push(`/dashboard/todos/${t.uid}`)}/>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            <div className="border flex justify-center items-center rounded cursor-pointer" onClick={() => _openCreateModal(p, s)}>
                              <Plus />
                            </div>
                          </div>
                        </td>
                      )}
                    </Droppable>
                  ))}
                </tr>
                <tr className="h-2"></tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <Droppable droppableId={"trash"}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="border border-gray-600 flex justify-center items-center mt-8 h-12 border-dashed rounded">
              <Trash color="red" />
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Modal isOpen={isOpen} toggle={_closeCreateModal}>
        <h3 className="text-center mb-2">Create Todo</h3>
        <TextInput label="title" onChange={setTitle} value={title} placeholder="Title..." />
        <div className="flex flex-wrap" style={{gap: 10}}>
          <Select options={TodoPriority}        className="flex-1" onChange={setSelectedPriority}                 value={selectedPriority} placeholder="Priority" />
          <Select options={TodoState}          className="flex-1" onChange={setSelectedStatus}                   value={selectedStatus}   placeholder="Status" />
          <Select options={projectsOptions} className="flex-1" onChange={e => setProject(e ? e.value : null)} 
                  value={project ? projectsOptions.find(i => i.value === project) ?? null : null} placeholder="Project" clearable />
        </div>
        <div className="flex justify-center -mb-4 mt-2">
          <Button color="secondary" onClick={_closeCreateModal}>Annuler</Button>
          <Button color="primary" onClick={_onCreateTodo}>Valider</Button>
        </div>
      </Modal>
    </div>
  )
}

export default DoubleTodoKanban;