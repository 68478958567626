import React, {useState, useEffect} from 'react';
import { useClock } from 'hydrogen';
import firebase from "firebase";
import useUI from 'contexts/ui';
import {Select} from "hydrogen";
import { useFirebaseCollection, useFirebaseDocument } from 'hydrogen/hooks/firebase';
import moment from "moment";
import { Pause, Play, Plus } from "react-feather";
import {toast} from "react-toastify";

const firestore = firebase.firestore;

const TimeManagerCurrentSlot = () => {
  const [ui] = useUI();
  const [userRef] = useState(firestore().collection("users").doc(ui.user.uid));
  const {data} = useFirebaseDocument(userRef, {});

  const [projectsRef] = useState(firestore().collection("projects").where("status", "==", "in_progress"));
  const {data: projects} = useFirebaseCollection(projectsRef, []);
  const [projectOptions, setProjectOptions] = useState([]);
  
  const [status, setStatus] = useState(null);
  const [project, setProject] = useState(null);
  const [start, setStart] = useState(null);
  const now = useClock();
  
  useEffect(() => {
    if (data.slotStart) {
      setStatus("in-progress");
      setStart(data.slotStart.toDate());
      setProject(data.slotProject);
    } else {
      setStatus(null);
      setStart(null);
      setProject(null);
    }
  }, [data])

  useEffect(() => setProjectOptions(projects.map(p => ({label: p.name, value: p.uid}))), [projects]);

  const _onEnd = async () => {
    try {
      await userRef.update({slotStart: null, slotTitle: "", slotProject: null});
      await userRef.collection("timeManagerSlots").add({
        start,
        end: new Date(),
        title: "",
        project: project ?? null
      });
    } catch (e) {
      console.error(e.message);
      toast.error(e.message);
    }
  }
  const _onStart = async () => {
    try {
      await userRef.update({slotStart: new Date(), slotTitle: ""});
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
  const _formatTime = () => {
    const hours = Math.floor(moment.duration(moment(now).diff(moment(start))).asHours());
    const minutes = Math.floor(moment.duration(moment(now).diff(moment(start))).asMinutes() % 60);
    const seconds = Math.floor(moment.duration(moment(now).diff(moment(start))).asSeconds() % 60);

    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  }

  return (
    <>
      {status === "in-progress" ? 
      <div className="flex items-center" style={{gap: 10}}>
        <div className="rounded-full bg-red-500" style={{height: 10, width: 10}}></div>
        <span className="text-xl font-semibold">{_formatTime()}</span>
        <Select options={projectOptions}
                onChange={(e) => userRef.update({slotProject: e ? e.value : null})}
                value={projectOptions.find((p) => p.value === project)}
                style={{marginBottom: 0, minWidth: 300}} />
        <Pause size={28} color="white" className="bg-gray-400 rounded-full cursor-pointer" onClick={_onEnd} />
      </div>
      : 
      <div className="flex items-center" style={{gap: 10}}>
        <div className="rounded-full bg-gray-400" style={{height: 10, width: 10}}></div>
        <Select options={projectOptions}
                onChange={(e) => userRef.update({slotProject: e ? e.value : null})}
                value={projectOptions.find((p) => p.value === project)}
                style={{marginBottom: 0, minWidth: 300}} />
        <Play size={28} color="white" className="bg-gray-400 rounded-full cursor-pointer" onClick={_onStart} style={{paddingLeft: 4}} />
      </div>
      }
    </>
  );
}

export default TimeManagerCurrentSlot;