import React, {useState} from "react";
import { Button, Card, Table, Modal, TextInput, ColorPicker, Select } from "hydrogen";
import { useFirebaseCollection } from "hydrogen/hooks/firebase";
import firebase from "firebase";
import { Edit, Plus, Trash } from "react-feather";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { ClientState } from "enums";

const firestore = firebase.firestore;

const Clients = () => {
  const history = useHistory();
  const [clientsRef] = useState(firestore().collection("clients"));
  const {data} = useFirebaseCollection(clientsRef, []);

  const [filters, setFilters] = useState({});
  const [display, setDisplay] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    let _data = [...data];
    if (filters.status) _data = _data.filter(item => item.status === filters.status);
    setDisplay(_data);
  }, [filters, data])

  const _onSubmit = async () => {
    try {
      setLoading(true);
      await firestore().collection("clients").add({
        name,
        color,
        status: "active",
        createdAt: firestore.FieldValue.serverTimestamp(),
      });
      _onClose();
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  }
  //TODO delete subprojects & timeManagerSlots associated to this client
  const _onDelete = async (uid) => {
    try {
      if (!window.confirm("Voulez-vous vraiment supprimer ce client ?")) return;
      setLoading(true);
      await firestore().collection("clients").doc(uid).delete();
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  }
  const _onClose = () => {
    setIsOpen(false);
    setName("");
    setColor("");
  }

  const _renderItem = (item) => (
    <tr key={item.uid}>
      <th>{item.name}</th>
      <td><div className="rounded-full" style={{backgroundColor: item.color ?? "#FFF", width: 15, height: 15 }}></div></td>
      <td>
        <span className={`${ClientState.find(i => i.value === item.status)?.color || ""} text-white px-1.5 py-0.5 rounded-full text-sm`}>
          {ClientState.find(i => i.value === item.status)?.label}
        </span>
      </td>
      <td className="flex">
        <Edit size={20} color="#1fb9f0" className="cursor-pointer" onClick={() => history.push(`/dashboard/clients/${item.uid}`)}/>
        {/*<Trash size={20} color="#ff5252" className="cursor-pointer ml-2" onClick={() => _onDelete(item.uid)}/>*/}
      </td>
    </tr>
  )

  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4">
          <h3>Clients</h3>
          <Button style={{margin: 0}} onClick={() => setIsOpen(true)}><Plus /></Button>
        </div>
        <div>
          <Select placeholder="Filtrer par status" options={ClientState} value={ClientState?.find(i => i.value === filters.status)} onChange={e => setFilters({...filters, status: e?.value})} clearable />
        </div>
        <Table 
          loading={loading}
          data={display}
          header={["Nom", "Couleur", "Statut", "Actions"]}
          renderItem={_renderItem}
        />
      </Card>
      <Modal isOpen={isOpen} toggle={_onClose} >
        <h2>Créer un client</h2>
        <div>
          <TextInput label="Name" placeholder="Name..." onChange={setName} value={name}/>
          <ColorPicker label="Color" placeholder="Color..." onChange={setColor} value={color} />
        </div>
        <div className="flex justify-center items-center">
          <Button onClick={_onClose} color="secondary">Annuler</Button>
          <Button onClick={_onSubmit} loading={loading}>Créer</Button>
        </div>
      </Modal>
    </>
  )
}

export default Clients;