import React, {useState, useEffect} from 'react';
import {Card, Spinner} from "hydrogen";
import useTimeManagerReport from 'hooks/useTimeManagerReport';
import moment from "moment";
import ApexCharts from 'react-apexcharts';
import firebase from "firebase";

const firestore = firebase.firestore;

const TimeManagerWeekPieReport = ({className, style}) => {
  const [startOfWeek] = useState(moment().startOf("week").toDate());
  const [endOfWeek] = useState(moment().endOf("week").toDate());
  const {report: weekReport, loading: weekReportLoading} = useTimeManagerReport(startOfWeek, endOfWeek);

  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    (async () => {
      if (weekReportLoading) return;
      let _parts = weekReport.filter(i => i.uid !== "sleep" && i.uid !== "Total");
      let _labels = _parts.map(i => i.project ? i.project.name : i.uid);
      let _series = _parts.map(i => i.duration[0] * 60 * 60 + i.duration[1] * 60 + i.duration[2]);
      let _colors = await Promise.all(_parts.map(async i => {
        if (!i.project) return "#9CA3AF";
        if (!i.project.client) return "#9CA3AF";
        let res = await firestore().collection("clients").doc(i.project.client).get();
        return res.exists ? res.data().color : "#9ca3af";
      }));
      let _wrap = _parts.map((_, id) => ({labels: _labels[id], series: _series[id], color: _colors[id]}));
      _wrap.sort((a, b) => b.series - a.series);
      _wrap = _wrap.filter(i => i.series > 0);

      //dispatch
      setLabels(_wrap.map(i => i.labels));
      setSeries(_wrap.map(i => i.series));
      setColors(_wrap.map(i => i.color));
      setLoading(false);
    })()
  }, [weekReport]);

  if (loading) return <Card><Spinner /></Card>
  return (
    <div className={className ?? ""} style={style ?? {}}>
      <h2 className="text-center">Week</h2>
      <ApexCharts 
        options= {{
          chart: {
            width: 380,
            type: "pie",
          },
          labels,
          colors,
          legend: {
            show: false,
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }}
        series={series}
        type="pie"
        width={380}
      />
    </div>
  );
}

export default TimeManagerWeekPieReport;