import React, {useState, useEffect} from 'react';
import {Tabs, Table, Card, Spinner, Button, Modal, NumInput, TextInput, FileUploader, Select} from "hydrogen";
import {useHistory} from "react-router-dom";
import {Download, Edit, Plus} from "react-feather";
import moment from 'moment';
import useUI from 'contexts/ui';
import useClientProjects from 'hooks/useClientProjects';
import firebase from "firebase";
import {DevisState} from "enums";

const firestore = firebase.firestore;

const Devis = () => {
  const history = useHistory();
  const [ui] = useUI();

  const [devis, setDevis] = useState([]);
  const {projects} = useClientProjects(ui.user.client);

  useEffect(() => {
    (async () => {
      let _devis = [];
      await Promise.all(projects.map(async (project) => {
        const devis = await firestore().collection("devis").where("project", "==", project.uid).get();
        _devis = _devis.concat(devis.docs.map((doc) => ({...doc.data(), uid: doc.id})));
      }));
      setDevis(_devis);
    })()
  }, [projects]);


  const _renderItem = (item) => (
    <tr key={item.uid}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>
        {projects?.find(p => p.uid === item.project)?.name}
      </td>
      <td>{moment(item.createdAt.toDate()).format("DD/MM/YYYY")}</td>
      <td>
        <span className={`text-sm text-white ${DevisState.find(i => i.value === item.status).color} rounded-full p-1`}>
          {DevisState.find(i => i.value === item.status).label}
        </span>
      </td>
      <td>
        {item.file ? <a href={item.file} target="_blank" >
          <Download />
        </a>
        : null}
      </td>
      <td>
      </td>
    </tr>
  )

  return (
    <>
      <Tabs
        activeTab={0}
        tabs={[{label: "Devis", route: "/dashboard/compta/devis"}, {label: "Factures", route: "/dashboard/compta/factures"}]}
        onTabChange={(id, tab) => history.push(`${tab.route}`)}
      />
      <Table 
        data={devis.sort((a, b) => b.id - a.id)}
        header={["ID", "Nom", "Projet", "Date", "Status", "Fichier"]}
        renderItem={_renderItem}
      />
    </>
  );
}

export default Devis;