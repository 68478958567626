import React, {useState, useEffect} from 'react';
import {Tabs, Table, Card, Spinner, Button, Modal, NumInput, TextInput, FileUploader, Select} from "hydrogen";
import {useHistory} from "react-router-dom";
import firebase from "firebase";
import { useFirebaseCollection } from 'hydrogen/hooks/firebase';
import {Download, Edit, Plus} from "react-feather";
import { toast } from 'react-toastify';
import ProjectSelect from 'components/ProjectSelect';
import moment from 'moment';
import useUI from 'contexts/ui';
import useClientProjects from 'hooks/useClientProjects';
import {FactureState} from "enums";

const firestore = firebase.firestore;

const Factures = () => {
  const history = useHistory();
  const [ui] = useUI();

  const [factures, setFactures] = useState([]);
  const {projects} = useClientProjects(ui.user.client);

  useEffect(() => {
    (async () => {
      let _factures = [];
      await Promise.all(projects.map(async (project) => {
        const factures = await firestore().collection("factures").where("project", "==", project.uid).get();
        _factures = _factures.concat(factures.docs.map((doc) => ({...doc.data(), uid: doc.id})));
      }));
      setFactures(_factures);
    })()
  }, [projects])

  const _renderItem = (item) => (
    <tr key={item.uid}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{projects?.find(p => p.uid === item.project)?.name}</td>
      <td>{moment(item.createdAt.toDate()).format("DD/MM/YYYY")}</td>
      <td>
        <span className={`text-sm text-white ${FactureState.find(s => s.value === item.status)?.color} px-2 py-1 rounded`}>
          {FactureState.find(s => s.value === item.status)?.label}
        </span>
      </td>
      <td>
        {item.file && <a href={item.file} target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-700">
          <Download />
          </a>
        }
      </td>
    </tr>
  )

  return (
    <>
      <Tabs
        activeTab={1}
        tabs={[{label: "Devis", route: "/dashboard/compta/devis"}, {label: "Factures", route: "/dashboard/compta/factures"}]}
        onTabChange={(id, tab) => history.push(`${tab.route}`)}
      />

      <Table 
        data={factures.sort((a, b) => b.id - a.id)}
        header={["ID", "Nom", "Projet", "Date", "Status", "Fichier"]}
        renderItem={_renderItem}
      />
    </>
  );
}

export default Factures;