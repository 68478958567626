import React, {useState, useEffect} from "react";
import firebase from "firebase";
import { Spinner, TextInput, Button, Checkbox } from "hydrogen";
import {useFirebaseCollection} from "hydrogen/hooks/firebase";
import useUI from "contexts/ui";
import { CheckSquare, Plus, Square, Trash } from "react-feather";

const firestore = firebase.firestore;

const SubTodo = (props) => {
  const {
    todoId
  } = props;
  const [ui] = useUI();
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");

  useEffect(() => {
    setLoading(true);
    const unsubscribe = firestore().collection("users").doc(ui.user.uid).collection("todos").doc(todoId).collection("subTodos").onSnapshot(snapshot => {
      const todos = snapshot.docs.map(doc => {
        return {
          uid: doc.id,
          ...doc.data()
        }
      });
      setTodos(todos);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [todoId])

  const _add = async () => {
    await firestore().collection("users").doc(ui.user.uid).collection("todos").doc(todoId).collection("subTodos").add({
      name,
      completed: false,
      createdAt: new Date(),
    });
    setName("");
  }
  const _remove = async (id) => {
    await firestore().collection("users").doc(ui.user.uid).collection("todos").doc(todoId).collection("subTodos").doc(id).delete();
  }
  const _toggle = async (id) => {
    await firestore().collection("users").doc(ui.user.uid).collection("todos").doc(todoId).collection("subTodos").doc(id).update({
      completed: !todos.find(i => i.uid === id).completed
    });
  }

  if (loading) return <Spinner />
  return (
    <>
      <h4 className="mb-2">Checklist</h4>
      <div className="flex flex-col">
        {todos.map(todo => (
          <div key={todo.uid} className="flex items-center justify-between border-b mb-2 pb-2">
            <div className="flex items-center">
              {todo.completed ? 
                <CheckSquare onClick={() => _toggle(todo.uid)} size={22} color="#3a82f5" className="cursor-pointer" /> 
                : <Square    onClick={() => _toggle(todo.uid)} size={22} color="#3a82f5" className="cursor-pointer" />
              }
              <span className="text-lg ml-1">{todo.name}</span>
            </div>
            <div>
              <Trash color="red" onClick={() => _remove(todo.uid)} size={22} className="cursor-pointer" />
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center mt-1" style={{gap: 10}}>
        <TextInput placeholder="Name..." value={name} onChange={setName} className="flex-1" style={{margin: 0}} onEnterPressed={_add} />
        <Button style={{margin: 0}} onClick={_add}><Plus /></Button>
      </div>
    </>
  )
}

export default SubTodo