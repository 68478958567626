import React, {useState, useEffect, useRef} from "react";
import useUI from "contexts/ui";
import {Card, Calendar} from "hydrogen";
import firebase from "firebase";

const firestore = firebase.firestore;

const Agenda = () => {
  const [ui] = useUI();
  const calendarContainerRef = useRef(null);
  const [calendarContainerHeight, setCalendarHeight] = useState(0);
  const [slots, setSlots] = useState([]);

  //responsive
  useEffect(() => {
    function _updateCalendarHeight() {
      if (calendarContainerRef === null || calendarContainerRef.current === null) return;
      setCalendarHeight(calendarContainerRef.current.offsetHeight);
    }
    window.addEventListener("resize", _updateCalendarHeight);
    _updateCalendarHeight();
    return () => window.removeEventListener("resize", _updateCalendarHeight);
  }, [calendarContainerRef])

  useEffect(() => {
    (async () => {
      //get client's projects
      let projects = await firestore().collection("projects").where("client", "==", ui.user.client).get();
      projects = projects.docs.map(doc => ({...doc.data(), uid: doc.id}));
      let _slots = [];
      await Promise.all(projects.map(async project => {
        let _projectSlot = await firestore().collection("calendar").where("project", "==", project.uid).get();
        _projectSlot = _projectSlot.docs.map(doc => ({...doc.data(), uid: doc.id}));
        _slots = [..._slots, ..._projectSlot];
      }))
      setSlots(_slots);
    })()
  }, [])

  return (
    <>
      <Card ref={calendarContainerRef} style={{height: "calc(100vh - 100px)"}}>
        <Calendar
          height={calendarContainerHeight - 50}
          events={slots.map(e => ({
            ...e,
            id: e.uid,
            start: e.start.toDate(),
            end: e.end.toDate(),
            backgroundColor: e.color
          }))}
        />
      </Card>
    </>
  )
}

export default Agenda;