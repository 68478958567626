import React, {useState, useEffect} from "react";
import firebase from "firebase";

const firestore = firebase.firestore;

const useClientProjects = (clientId) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const _onRefresh = async () => {
    setLoading(true);
    try {
      const projects = await firestore().collection("projects").where("client", "==", clientId).get();
      setProjects(projects.docs.map((doc) => ({...doc.data(), uid: doc.id})));
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    _onRefresh();
  }, []);

  return {projects, loading, error, _onRefresh};
}

export default useClientProjects;