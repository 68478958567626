import React, {useState, useEffect} from "react";
import DoubleTodoKanban from "components/DoubleTodoKanban";
import firebase from "firebase";
import useUI from "contexts/ui";

const firestore = firebase.firestore;

const Todos = () => {
  const [ui] = useUI();
  const [todosRef] = useState(firestore().collection("users").doc(ui.user.uid).collection("todos"));

  return (
    <DoubleTodoKanban firestoreRef={todosRef} />
  )
}

export default Todos;