import {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import useRouterConfig from "routes";
import {Card, Spinner, TextInput, Select, TextArea, Button} from "hydrogen";
import firebase from "firebase";
import useUI from "contexts/ui";
import {useFirebaseDocumentOnce, useFirebaseCollectionOnce} from "hydrogen/hooks/firebase";
import { toast } from "react-toastify";
import SubTodo from "./_subTodo";

const firestore = firebase.firestore;

const priorityOptions = [
  {label: "pour hier",   value: "0", bgColor1: "bg-red-600",    bgColor2: "bg-red-800"},
  {label: "court terme", value: "1", bgColor1: "bg-yellow-600", bgColor2: "bg-yellow-800"},
  {label: "moyen terme", value: "2", bgColor1: "bg-indigo-600", bgColor2: "bg-indigo-800"},
  {label: "long terme",  value: "3", bgColor1: "bg-blue-600",   bgColor2: "bg-blue-800"},
];
const statusOptions = [
  {label: "todo",        value: "0"},
  {label: "paused",      value: "1"},
  {label: "in progress", value: "2"},
  {label: "done",        value: "3"},
];

const Todo = () => {
  const [ui] = useUI();
  const history = useHistory();
  const {routes} = useRouterConfig();
  const {id} = useParams();

  const [todoRef] = useState(firestore().collection("users").doc(ui.user.uid).collection("todos").doc(id));
  const {data: todoData, loading: todoLoading, update} = useFirebaseDocumentOnce(todoRef, {});
  const [projectRef] = useState(firestore().collection("projects"));
  const {data: projectsData, loading: projectsLoading} = useFirebaseCollectionOnce(projectRef, []);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(null);
  const [priority, setPriority] = useState(null);
  const [project, setProject] = useState(null);

  const [formLoading, setFormLoading] = useState(false);
  const [projectsOptions, setProjectsOptions] = useState([]);

  useEffect(() => {
    if (todoLoading) return;

    if (todoData) {
      setTitle(todoData.title ?? "");
      setDescription(todoData.description ?? "");
      setStatus(statusOptions.find(i => i.value === todoData.status));
      setPriority(priorityOptions.find(i => i.value === todoData.priority));
      setProject(todoData.project ?? null);
    }
  }, [todoData, todoLoading, projectsData, projectsLoading]);
  useEffect(() => {
    if (projectsLoading) return;

    if (projectsData) {
      setProjectsOptions(projectsData.map(i => ({label: i.name, value: i.uid})));
    }
  }, [projectsLoading])

  const _update = async () => {
    setFormLoading(true);
    try {
      await update({
        title,
        description,
        status: status.value,
        priority: priority.value,
        project,
        updatedAt: new Date(),
      })
      toast.success("Todo updated");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setFormLoading(false);
  }

  if (todoLoading, projectsLoading) return <Card><Spinner /></Card>
  return (
    <div className="flex flex-col" style={{gap: 10}}>
      <Card>
        <TextInput placeholder="Title..." label="Titre:" value={title} onChange={setTitle} onEnterPressed={_update} />
        <div className="flex flex-wrap" style={{gap: 10}}>
          <Select label="Status:" value={status} options={statusOptions} onChange={setStatus} className="flex-1"/>
          <Select label="Priority:" value={priority} options={priorityOptions} onChange={setPriority} className="flex-1" />
          <Select label="Project:" 
                  value={project ? projectsOptions.find(i => i.value === project) : null} 
                  options={projectsOptions} 
                  onChange={e => setProject(e ? e.value : null)} 
                  className="flex-1" clearable />
        </div>
        <TextArea value={description} onChange={setDescription} placeholder="Description..." label="Description:" />
        <div className="flex justify-center items-center">
          <Button color="primary" onClick={_update} loading={formLoading}>Enregistrer</Button>
        </div>
      </Card>
      <Card>
        <SubTodo todoId={id} />
      </Card>
    </div>
  )
}

export default Todo;