import TimeManagerWeekPieReport from "./TimeManagerWeekPieReport";
import TimeManagerDayPieReport from "./TimeManagerDayPieReport";
import TimeManagerTextReport from "./TimeManagerTextReport";
import TimeManagerWeekGraph from "./TimeManagerWeekGraph";

import { Card } from "hydrogen";

const Overview = () => {
  return (
    <div className="flex flex-col" style={{gap: 10}}>
      <TimeManagerTextReport />
      <Card className="flex justify-center">
        <TimeManagerDayPieReport />
        <TimeManagerWeekPieReport />
      </Card>
      <TimeManagerWeekGraph />
    </div>
  )
}

export default Overview;