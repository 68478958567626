import React, {useState, useEffect} from 'react';
import {Tabs, Table, Card, Spinner, Button, Modal, NumInput, TextInput, FileUploader, Select} from "hydrogen";
import {useHistory} from "react-router-dom";
import firebase from "firebase";
import { useFirebaseCollection } from 'hydrogen/hooks/firebase';
import {Download, Edit, Plus} from "react-feather";
import { toast } from 'react-toastify';
import ProjectSelect from 'components/ProjectSelect';
import moment from 'moment';

const firestore = firebase.firestore;

const statesOptions = [
  {label: "Impayé",                  value: "unpaid",           color: "bg-gray-500"},
  {label: "En attente de signature", value: "pendingSignature", color: "bg-yellow-500"},
  {label: "En attente d'envoi"     , value: "pendingSending",   color: "bg-yellow-500"},
  {label: "En attente de paiement" , value: "pendingPayment",   color: "bg-yellow-500"},
  {label: "Payée"                  , value: "paid"          ,   color: "bg-green-500"},
  {label: "Invalide"               , value: "invalid"       ,   color: "bg-red-500"},
  {label: "Annulée"                , value: "canceled"      ,   color: "bg-red-500"},
]

const Factures = () => {
  const history = useHistory();
  const [factureRef] = useState(firestore().collection("factures"));
  const {data: factureData, loading: factureLoading} = useFirebaseCollection(factureRef, []);

  const [projectsRef] = useState(firestore().collection("projects"));
  const {data: projectsData} = useFirebaseCollection(projectsRef, []);

  const [isOpen, setIsOpen] = useState(false);
  const [factureOpen, setFactureOpen] = useState(null);
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [project, setProject] = useState(null);
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("pendingSending");

  useEffect(() => {
    if (!factureOpen) {
      setId(factureData.length + 1);
      setName("");
      setProject(null);
      setFile(null);
      setStatus("pendingSending");
    } else {
      setId(factureOpen.id);
      setName(factureOpen.name);
      setProject(factureOpen.project);
      setFile(factureOpen.file);
      setStatus(factureOpen.status);
    }
  }, [factureOpen, isOpen])
  const _onClose = () => {
    setIsOpen(false);
    setId(0);
    setName("");
    setProject(null);
    setFile(null);
    setFactureOpen(null);
  }
  const _onSubmit = async () => {
    setLoading(true);
    try {
      if (factureOpen) {
        await firestore().collection("factures").doc(factureOpen.uid).update({
          id,
          name: name ?? null,
          project: project ?? null,
          file: file ?? null,
          status,
        })
      } else {
        await firestore().collection("factures").add({
          id,
          name: name ?? null,
          project: project ?? null,
          file: file ?? null,
          status,
          createdAt: new Date(),
        })
      }
      _onClose();

    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  }
  const _onRemove = async () => {
    try {
      if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette facture ?")) return;
      await firestore().collection("factures").doc(factureOpen.uid).delete();
      _onClose();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
  const _renderItem = (item) => (
    <tr key={item.uid}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{projectsData?.find(p => p.uid === item.project)?.name}</td>
      <td>{moment(item.createdAt.toDate()).format("DD/MM/YYYY")}</td>
      <td>
        <span className={`text-sm text-white ${statesOptions.find(s => s.value === item.status)?.color} px-2 py-1 rounded`}>
          {statesOptions.find(s => s.value === item.status)?.label}
        </span>
      </td>
      <td>
        {item.file && <a href={item.file} target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-700">
          <Download />
          </a>
        }
      </td>
      <td>
        <Edit className="cursor-pointer" onClick={() => {setFactureOpen(item); setIsOpen(true)}}/>
      </td>
    </tr>
  )

  return (
    <>
      <Tabs
        activeTab={1}
        tabs={[{label: "Devis", route: "/dashboard/compta/devis"}, {label: "Factures", route: "/dashboard/compta/factures"}]}
        onTabChange={(id, tab) => history.push(`${tab.route}`)}
      />

      <div className="flex justify-end">
        <Button color="primary" onClick={() => setIsOpen(true)}><Plus /></Button>
      </div>
      <Table 
        loading={factureLoading}
        data={factureData.sort((a, b) => b.id - a.id)}
        header={["ID", "Nom", "Projet", "Date", "Status", "Fichier", "Action"]}
        renderItem={_renderItem}
      />

      {/* Modal */}
      <Modal isOpen={isOpen} toggle={_onClose}>
        <div className="mb-4"><h2>Créer une facture</h2></div>
        <div>
          <NumInput label="ID" value={id} onChange={setId} />
          <TextInput label="Nom" value={name} onChange={setName} />
          <FileUploader label="Fichier" value={file} onChange={setFile} accept=".pdf"/>
          <ProjectSelect label="Projet" value={project} onChange={setProject} withArchived />
          <Select label="Status" value={statesOptions.find(i => i.value === status)} onChange={e => setStatus(e.value)} options={statesOptions} />
        </div>
        <div className="-mb-4 mt-4 flex justify-center items-center">
          <Button color="secondary" onClick={_onClose}>Annuler</Button>
          <Button color="primary" onClick={_onSubmit} loading={loading}>Valider</Button>
          {factureOpen ? <Button color="danger" onClick={_onRemove}>Supprimer</Button> : null}
        </div>
      </Modal>
    </>
  );
}

export default Factures;