import React, {useState, useEffect} from "react";
import * as PT from "prop-types";

const Tabs = (props) => {
  const {
    tabs,
    activeTab,
    onTabChange,
  } = props;

  return (
    <div>
      <div className="flex flex-wrap border-b-2">
        {tabs.map((tab, index) => (
          <>
            {typeof(tab.label) === "string" ? 
              <span className={`p-4 font-semibold cursor-pointer border-b-2 text-gray-600 hover:text-blue-300
                                ${index === activeTab ? " border-blue-500 text-blue-500": ""}`}
                    style={{position: "relative", top: 2}}
                    onClick={() => onTabChange(index, tab)}>
                {tab.label}
              </span>
              :
              <span onClick={() => onTabChange(index, tab)}>
                {tab.label}
              </span>
            }
          </>
        ))}
      </div>
      <div className="pt-3">
        {tabs[activeTab].content ? tabs[activeTab].content : null}
      </div>
    </div>
  )
}

Tabs.defaultProps = {
  tabs: [],
  activeTab: 0,
  onTabChange: () => {},
}

export default Tabs;