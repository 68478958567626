import React, {useState, useEffect} from "react";
import {TwitterPicker} from "react-color";

const ColorPicker = (props) => {
  const {
    value,
    onChange,
    label,
    invalid,
    disabled,
    placeholder,
    className,
    style,
  } = props;

  const [display, setDisplay] = useState(false);

  const _onChange = (e) => {
    setDisplay(false);
    onChange(e.hex);
  }

  return (
    <div className={`mb-3 ${className}`}  style={{minWidth: 200, ...style}} >
      {label ? <span className="block text-gray-600 mb-1">{label}</span> : null}
      <div className={`h-9 border w-full p-1 rounded`} 
             onClick={() => setDisplay(true)} >
        <div className="h-full rounded" style={{backgroundColor: value ?? "#3e84d7"}}>
        </div>
      </div>
      {display ? 
        <TwitterPicker color={value} onChange={_onChange} />
      : null}
    </div>
  )
}

export default ColorPicker;