
const config = {
  lang: "fr",
  driver: "firebase", //["firebase", "strapi"]
  firebase: {
    apiKey: "AIzaSyCbbVLDQRHFS8jG1SarJ1XsmnAsFY2vePs",
    authDomain: "gga-intra.firebaseapp.com",
    projectId: "gga-intra",
    storageBucket: "gga-intra.appspot.com",
    messagingSenderId: "637139545428",
    appId: "1:637139545428:web:95363511e85f8558812cc1",
    enabledPersistence: false,
  },
  debug: true,
}

export default config;