import {useState, useEffect} from "react";
import firebase from "firebase";
import { useFirebaseCollection } from "hydrogen/hooks/firebase";
import useUI from "contexts/ui";
import moment from "moment";

const firestore = firebase.firestore;

const useTimeManagerReport = (start, end) => {
  const [ui] = useUI();
  const [slotsRef, setSlotsRef] = useState(firestore().collection("users").doc(ui.user.uid)
                                         .collection("timeManagerSlots")
                                         .where("start", ">=", start).where("start", "<=", end));
  const {data: slots} = useFirebaseCollection(slotsRef, []);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setSlotsRef(firestore().collection("users").doc(ui.user.uid)
                           .collection("timeManagerSlots")
                           .where("start", ">=", start).where("start", "<=", end))
  }, [start, end]);

  useEffect(() => {
    (async () => {
      if (slots) {
        let rows = [
          {uid: "others", project: null, duration: [0, 0, 0], slots: []},
          {uid: "sleep", project: null, duration: [0, 0, 0], slots: []},
        ];

        //divide in row
        await Promise.all(slots.map(async (slot) => {
          let _project = slot.project ?? null;

          let rowId = null;
          if (_project === "sleep") {
            rowId = 1
          } else {
            if (_project) _project = await firestore().collection("projects").doc(_project).get();
            if (_project && _project.exists) _project = {..._project.data(), uid: _project.id};
            if (_project && !rows.find(r => r.uid === _project.uid)) rows.push({uid: _project.uid, project: _project, duration: [0, 0, 0], slots: []});
            if (_project) _project = _project.uid;
            if (_project === null) _project = "others";
            rowId = rows.findIndex(r => r.uid === _project);
          }

          rows[rowId].slots.push({
            ...slot,
            start: slot.start.toDate(),
            end: slot.end.toDate(),
          })
        }));

        //calculate duration
        rows = rows.map(row => {
          let _secondes = 0;
          let duration = [0, 0, 0];
          row.slots.forEach(slot => _secondes += moment.duration(moment(slot.end).diff(moment(slot.start))).asSeconds());
          duration[0] = Math.floor(_secondes / 3600);
          duration[1] = Math.floor((_secondes - duration[0] * 3600) / 60);
          duration[2] = Math.floor(_secondes - duration[0] * 3600 - duration[1] * 60);
          row.duration = duration;
          return row;
        })

        //compute total report
        let total = {uid: "Total", project: null, duration: [0, 0, 0], slots: []};
        let _secondes = 0;
        await Promise.all(rows.filter(i => i.uid !== "sleep").map(async (row) => {
          _secondes += row.duration[0] * 3600 + row.duration[1] * 60 + row.duration[2];
        }));
        total.duration = [Math.floor(_secondes / 3600), Math.floor((_secondes % 3600) / 60), Math.floor(_secondes % 60)];
        rows.push(total);

        setReport(rows);
        setLoading(false);
      }
    })()
  }, [slots])

  return ({report, loading})
}

export default useTimeManagerReport;