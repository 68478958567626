import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import {Download, Trash, Upload} from "react-feather";
import firebase from "firebase";
import {v4 as uuidv4} from "uuid";

const storage = firebase.storage;

const FileUploader = (props) => {
  const {
    className,
    style,
    onChange,
    value,
    label,
    accept,
    disabled,
  } = props;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (disabled) return;
    try {
      let _parentRef = `/files`;
      let extension = e.target.files[0].name.split(".").pop();
      let hash = `${uuidv4()}.${extension}`;
      await storage().ref(`${_parentRef}/${hash}`).put(e.target.files[0]);
      let url = await storage().ref(_parentRef).child(hash).getDownloadURL();
      onChange(url);
      e.target.value = null;
    } catch (e) {
      console.error(e.message);
      toast.error(e.message);
    }
  }

  const handleRemove = async () => {
    if (!disabled) onChange(null)
  }

  return (
    <div className={`fileUploader ${className ?? ""} mb-3`} style={{minWidth: 200, ...style}}>
      {label ? <span className="block text-gray-600 mb-1">{label}</span> : null}
      <div className={`flex`}>
        <label className={`border rounded-l flex-1 h-9 flex items-center px-2 overflow-hidden whitespace-nowrap `} for="file">
          {value ? value.split("/").pop().split("?")[0].split("%").pop() : "Choisir un fichier"}
        </label>
        <label className={`flex items-center px-2 border-t border-b border-r cursor-pointer hover:bg-gray-100 ${!value ? "rounded-r" : ""}`} for="file">
          <Upload size={24} />
        </label>
        {value ? <a href={value} target="_blank" className={`flex items-center border-t border-r border-b px-2 cursor-pointer hover:bg-gray-100`}>
          <Download size={24} />
        </a>: null}
        {value ? <div className={`flex items-center border-t border-r border-b rounded-r cursor-pointer px-2 hover:bg-gray-100`} onClick={handleRemove}>
          <Trash size={24} />
        </div>: null}
      </div>
      {!disabled ? 
        <input id="file" type="file" placeholder="Sélectionner votre fichier" accept={accept ?? "*"} onChange={handleSubmit} style={{visibility: "hidden", display: "none"}} />
      : null}
    </div>
  )
}

FileUploader.defaultProps = {
  style: {},
  className: ""
}

export default FileUploader;