import React, {useState, useEffect} from "react";
import {Tabs, Card, Spinner, TextInput, ColorPicker, Table} from "hydrogen";
import { useHistory, useParams } from "react-router-dom";
import { useFirebaseCollection } from "hydrogen/hooks/firebase";
import firebase from "firebase";
import useRouterConfig from "routes";
import { ProjectState } from "enums";
import {Edit, Trash} from "react-feather";
import {toast} from "react-toastify";

const firestore = firebase.firestore;

const ClientProjects = () => {
  const history = useHistory();
  const {routes} = useRouterConfig();
  const {id} = useParams();
  const [projectRef, setProjectRef] = useState(firestore().collection("projects").where("client", "==", id));
  const {data, loading} = useFirebaseCollection(projectRef, []);

  useEffect(() => {setProjectRef(firestore().collection("projects").where("client", "==", id))}, [id]);

  const _onDelete = async (uid) => {
    try {
      if (!window.confirm("Voulez-vous vraiment supprimer ce projet ?")) return;
      await firestore().collection("projects").doc(uid).delete();
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
  }

  const _renderItem = (item) => {
    return (
      <tr key={item.uid}>
        <th>{item.name}</th>
        <td>
          <span className={`${ProjectState.find(s => s.value === item.status)?.color || "bg-gray-500"} text-white text-sm px-1.5 py-0.5 rounded-full`}>
            {ProjectState.find(s => s.value === item.status)?.label || item.status}
          </span>
        </td>
        <td className="flex">
          <Edit size={20} color="#1fb9f0" className="cursor-pointer" onClick={() => history.push(`/dashboard/projets/${item.uid}`)}/>
          <Trash size={20} color="#ff5252" className="cursor-pointer ml-2" onClick={() => _onDelete(item.uid)}/>
        </td>
      </tr>
    )
  }

  return (
    <Card>
      <Tabs 
        activeTab={1}
        onTabChange={(id, tab) => history.push(`${tab.route}`)}
        tabs={routes.filter(r => r.route.startsWith("clients/:id")).map(r => ({label: r.name, route: `/dashboard/${r.route}`.replace(":id", id)}))}
      />
      {loading ? <Spinner /> : 
        <Table 
          data={data}
          header={["Projet", "Status", "Actions"]}
          renderItem={_renderItem}
        />
      }
    </Card>
  )
}

export default ClientProjects;