import React, {useState, useEffect} from "react";
import {Tabs, Card, Spinner, TextInput, ColorPicker, Button, Select} from "hydrogen";
import { useHistory, useParams } from "react-router-dom";
import { useFirebaseDocumentOnce } from "hydrogen/hooks/firebase";
import firebase from "firebase";
import useRouterConfig from "routes";
import { toast } from "react-toastify";
import { ClientState } from "enums";

const firestore = firebase.firestore;

const ClientInfos = () => {
  const history = useHistory();
  const {routes} = useRouterConfig();
  const {id} = useParams();
  const [clientRef, setClientRef] = useState(firestore().collection("clients").doc(id));
  const {data, loading, update} = useFirebaseDocumentOnce(clientRef, {});

  const [name, setName] = useState("");
  const [color, setcolor] = useState("");
  const [status, setStatus] = useState("");
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {setClientRef(firestore().collection("clients").doc(id))}, [id]);
  useEffect(() => {
    if (data) {
      setName(data.name);
      setcolor(data.color);
      setStatus(ClientState.find(item => item.value === data.status));
    }
  }, [data])

  const _onSubmit = async () => {
    try {
      setFormLoading(true);
      await update({
        name: name ?? "",
        color,
        status: status ? status.value : null,
        updatedAt : new Date()
      });
      toast.success("Client updated");
    } catch (e) {
      console.error(e.message);
      toast.error("Une erreur est survenue");
    }
    setFormLoading(false);
  }

  return (
    <Card>
      <Tabs 
        activeTab={0}
        onTabChange={(id, tab) => history.push(`${tab.route}`)}
        tabs={routes.filter(r => r.route.startsWith("clients/:id")).map(r => ({label: r.name, route: `/dashboard/${r.route}`.replace(":id", id)}))}
      />
      {loading ? <Spinner /> : 
        <div>
          <div className="flex flex-wrap" style={{gap: 10}}>
            <TextInput label="Name" value={name} onChange={setName} className="flex-1" />
            <ColorPicker label="Color" value={color} onChange={setcolor} className="flex-1" />
            <Select label="Status" value={status} onChange={setStatus} className="flex-1"
                    options={ClientState} />
          </div>
          <div className="flex justify-center mt-4 -mb-4" >
            <Button onClick={_onSubmit} loading={formLoading}>Sauvegarder</Button>
          </div>
        </div>
      }
    </Card>
  )
}

export default ClientInfos;