const ProjectState = [
  {label: "Offre commerciale", value: "commercial_offer", color: "bg-yellow-400"},
  {label: "En cours",          value: "in_progress",      color: "bg-blue-400"},
  {label: "Terminé",           value: "finished",         color: "bg-green-500"},
  {label: "Abandonné",         value: "aborded",          color: "bg-gray-400"},
  {label: "Archive",           value: "archive",          color: "bg-gray-400" },
  {label: "En pause",          value: "paused",           color: "bg-yellow-400"},
];

const ClientState = [
  {label: "Actif",   value: "active",  color: "bg-green-500"},
  {label: "Archive", value: "archive", color: "bg-gray-400"}
]

const TodoState = [
  {label: "todo",        value: "0",},
  {label: "paused",      value: "1",},
  {label: "in progress", value: "2",},
  {label: "done",        value: "3",},
]

const TodoPriority = [
  {label: "pour hier",   value: "0", bgColor1: "bg-red-600",    bgColor2: "bg-red-800"},
  {label: "court terme", value: "1", bgColor1: "bg-yellow-600", bgColor2: "bg-yellow-800"},
  {label: "moyen terme", value: "2", bgColor1: "bg-indigo-600", bgColor2: "bg-indigo-800"},
  {label: "long terme",  value: "3", bgColor1: "bg-blue-600",   bgColor2: "bg-blue-800"},
]

const UserRole = [
  {label: "Administrateur", value: "admin"},
  {label: "Client", value: "client"},
]

const FactureState = [
  {label: "Impayé",                  value: "unpaid",           color: "bg-gray-500"},
  {label: "En attente de signature", value: "pendingSignature", color: "bg-yellow-500"},
  {label: "En attente d'envoi"     , value: "pendingSending",   color: "bg-yellow-500"},
  {label: "En attente de paiement" , value: "pendingPayment",   color: "bg-yellow-500"},
  {label: "Payée"                  , value: "paid"          ,   color: "bg-green-500"},
  {label: "Invalide"               , value: "invalid"       ,   color: "bg-red-500"},
  {label: "Annulée"                , value: "canceled"      ,   color: "bg-red-500"},
]

const DevisState = [
  {label: "En attente de signature", value: "pendingSignature", color: "bg-yellow-500"},
  {label: "En attente d'envoi"     , value: "pendingSending", color: "bg-yellow-500"},
  {label: "Signé"                  , value: "signed"        , color: "bg-green-500"},
  {label: "Annulé"                 , value: "canceled"      , color: "bg-red-500"},
  {label: "Refusé"                 , value: "refused"       , color: "bg-red-500"},
  {label: "Invalide"               , value: "invalid"       , color: "bg-red-500"},
]

export {
  ProjectState,
  ClientState,
  TodoState,
  TodoPriority,
  UserRole,
  FactureState,
  DevisState
}
