import React, {useEffect, useState} from "react";
import useRouterConfig from "routes";
import AccessDenied from "../../../pages/401";

const CustomModel = (props) => {
  const {
    route,
  } = props;

  return <route.component />
}

export default CustomModel;