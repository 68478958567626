import React, {useState, useEffect} from "react";
import { Select } from "hydrogen";
import { useFirebaseCollectionOnce } from "hydrogen/hooks/firebase";
import firebase from "firebase";

const firestore = firebase.firestore;
const ClientSelect = (props) => {
  const {
    value,
    onChange,
    label,
    clearable,
  } = props;

  const [clientsRef] = useState(firestore().collection("clients"));
  const {data: clientsData, loading: projectsLoading} = useFirebaseCollectionOnce(clientsRef, []);

  const [_value, _setValue] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let client = null;
    if (value === null || !(client = options.find((p) => p.value === value))) 
      _setValue(null); 
    else {
      _setValue(client);
    }
  }, [value, options]);

  useEffect(() => {
    if (clientsData) {
      const _options = clientsData.map((client) => ({
        value: client.uid,
        label: client.name,
      }));
      setOptions(_options);
    }
  }, [clientsData]);

  const _onChange = (e) => {if (onChange) onChange(e?.value ?? null);}

  return (
    <Select loading={projectsLoading}
            label={label}
            onChange={_onChange}
            value={_value}
            clearable={clearable}
            options={options} />
  )
}

export default ClientSelect;