import {useState, useEffect} from "react";
import firebase from "firebase";
import {toast} from "react-toastify";
import { TextInput, Button, Card, Spinner, Select } from "hydrogen";
import { useFirebaseDocumentOnce } from "hydrogen/hooks/firebase";
import { useParams } from "react-router-dom";
import { UserRole } from "enums";
import ClientSelect from "components/ClientSelect";

const firestore = firebase.firestore;

const User = () => {
  const {id} = useParams();
  const [userRef, setUserRef] = useState(firestore().collection("users").doc(id));
  const {data, loading, update} = useFirebaseDocumentOnce(userRef, {});

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mail, setMail] = useState("");
  const [role, setRole] = useState(null);
  const [client, setClient] = useState(null);
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (data) {
      setName(data.name);
      setSurname(data.surname);
      setMail(data.mail);
      setRole(data.role);
      setClient(data.client);
    }
  }, [data, loading]);

  const _handleUpdate = () => {
    setFormLoading(true);
    try {
      update({
        name: name ?? "",
        surname: surname ?? "",
        role: role ?? "",
        client,
        updatedAt: new Date()
      });
      toast.success("User updated");
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue")
    }
    setFormLoading(false);
  }

  if (loading) return <Spinner/>;
  return (
    <Card>
      <h3>{data.name} ({data.mail})</h3>
      <div>
        <div className="flex " style={{gap: 10}}>
          <TextInput className="flex-1" label="Surname" value={surname} onChange={setSurname}/>
          <TextInput className="flex-1" label="Nom" value={name} onChange={setName}/>
        </div>
        <Select options={UserRole} 
                label="Role"
                value={role ? UserRole.find(i => i.value === role) : null}
                onChange={e => setRole(e.value)}
        />
        <TextInput label="Email" value={mail} onChange={setMail} disabled />
        <ClientSelect value={client} onChange={setClient} label="Ref Client" clearable />
      </div>
      <div className="-mb-2 mt-2 flex justify-center">
        <Button color="primary" onClick={_handleUpdate}>Enregistrer</Button>
      </div>
    </Card>
  )
}

export default User;