
import BlankLayout from "layouts/Blank";
import DashboardLayout from "layouts/Dashboard";

import { faHome, faCoins, faUsers, faDiagramProject, faClock, faSquareCheck, faCalendar, faFile } from "@fortawesome/free-solid-svg-icons";

import Login from "pages/blank/login";
import Forgot from "pages/blank/forgotPassword";
import ProfilePage from "pages/dashboard/Profile";

import AdminOverview       from "pages/dashboard/Admin/Overview/index.js";
import AdminClients        from "pages/dashboard/Admin/Clients";
import AdminClientInfos    from "pages/dashboard/Admin/Clients/ClientInfos";
import AdminClientProjects from "pages/dashboard/Admin/Clients/ClientProjects";
import AdminComptabilite   from "pages/dashboard/Admin/Compta";
import AdminDevis          from "pages/dashboard/Admin/Compta/Devis";
import AdminFacture        from "pages/dashboard/Admin/Compta/Factures";
import AdminProjets        from "pages/dashboard/Admin/Projets";
import AdminProject        from "pages/dashboard/Admin/Projets/Project";
import AdminProjectTasks   from "pages/dashboard/Admin/Projets/_tasks";
import AdminTimeManager    from "pages/dashboard/Admin/TimeManager";
import AdminTodos          from "pages/dashboard/Admin/Todo";
import AdminTodo           from "pages/dashboard/Admin/Todo/single";
import AdminAgenda         from "pages/dashboard/Admin/Agenda";
import AdminUsers          from "pages/dashboard/Admin/Users";
import AdminUser           from "pages/dashboard/Admin/Users/User";

import ClientOverview      from "pages/dashboard/Client/Overview";
import ClientComptabilite  from "pages/dashboard/Client/Compta";
import ClientDevis         from "pages/dashboard/Client/Compta/Devis";
import ClientFacture       from "pages/dashboard/Client/Compta/Factures";
import ClientAgenda        from "pages/dashboard/Client/Agenda";

import useUI from "contexts/ui";

const useRouterConfig = () => {
  const [ui] = useUI();

  /*
    required:
      name:string
      route:string
      component:JSX
      access:string
  */
  const layouts = [
    {name: "dashboard", route: "/dashboard", component: DashboardLayout, access: "connected"},
    {name: "blank",     route: "",           component: BlankLayout,     access: "public"},
  ];

  /*
    required:
      name:string
      route:string
      layout:string
      component:JSX
    optional:
      access:string (default: herited from layout)
      type:string (default: "custom")
      hide (default: false)
      icon:fontAwesomeIcon (default: null)
  */
  const routes = [
    {name: "login",    route: "",         layout: "blank",     access: "public",    component: Login,       hide: true},
    {name: "forgot",   route: "forgot",   layout: "blank",     access: "public",    component: Forgot,      hide: true},
    {name: "profile",  route: "profile",  layout: "dashboard", access: "connected", component: ProfilePage, hide: true},
    
    //-------------------------- Admin -------------------------------//
    ...(ui.user && ui.user.role === "admin" ? [
      {name: "overview",     route: "",                     layout: "dashboard", component: AdminOverview, icon: faHome},
      {name: "agenda",       route: "agenda",               layout: "dashboard", component: AdminAgenda, icon: faCalendar},
      {name: "time manager", route: "time-manager",         layout: "dashboard", component: AdminTimeManager, icon: faClock},
      {name: "todo",         route: "todos/:id",            layout: "dashboard", component: AdminTodo,  hide: true},
      {name: "todos",        route: "todos",                layout: "dashboard", component: AdminTodos, icon: faSquareCheck},
      {name: "Informations", route: "clients/:id",          layout: "dashboard", component: AdminClientInfos, hide: true},
      {name: "Projets",      route: "clients/:id/projects", layout: "dashboard", component: AdminClientProjects, hide: true},
      {name: "clients",      route: "clients",              layout: "dashboard", component: AdminClients, icon: faUsers},
      {name: "projet" ,      route: "projets/:id",          layout: "dashboard", component: AdminProject, hide: true},
      {name: "todo",         route: "projets/:id/tasks",    layout: "dashboard", component: AdminProjectTasks, hide: true},
      {name: "projets",      route: "projets",              layout: "dashboard", component: AdminProjets, icon: faDiagramProject},
      {name: "devis",        route: "compta/devis",         layout: "dashboard", component: AdminDevis, hide: true},
      {name: "factures",     route: "compta/factures",      layout: "dashboard", component: AdminFacture, hide: true},
      {name: "compta",       route: "compta",               layout: "dashboard", component: AdminComptabilite, icon: faFile},
      {name: "users",        route: "users",                layout: "dashboard", component: AdminUsers, icon: faUsers},
      {name: "user",         route: "users/:id",            layout: "dashboard", component: AdminUser, hide: true},
    ] : []),

    //-------------------------- Client -------------------------------//
    ...(ui.user && ui.user.role === "client" ? [
      {name: "Accueil",      route: "",                     layout: "dashboard", access: "client", component: ClientOverview, icon: faHome},
      {name: "devis",        route: "compta/devis",         layout: "dashboard", access: "client", component: ClientDevis, hide: true},
      {name: "factures",     route: "compta/factures",      layout: "dashboard", access: "client", component: ClientFacture, hide: true},
      {name: "Facturation",  route: "compta",               layout: "dashboard", access: "client", component: ClientComptabilite, icon: faCoins},
      {name: "agenda",       route: "agenda",               layout: "dashboard", access: "client", component: ClientAgenda, icon: faCalendar},
    ] : []),
  ];

  return {layouts, routes};
}

export default useRouterConfig;