import React, {useState, useEffect} from "react";
import firebase from "firebase";
import { useFirebaseCollection } from "hydrogen/hooks/firebase";
import {Edit, Plus, Trash} from "react-feather";
import {Card, Button, Table, TextInput, Modal, Select} from "hydrogen";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import useUI from "contexts/ui";
import { ProjectState } from "enums";
const firestore = firebase.firestore;

const Projets = () => {
  const history = useHistory();
  const [ui] = useUI();
  const [projetsRef] = useState(firestore().collection("projects"));
  const {data, create, remove, error} = useFirebaseCollection(projetsRef, []);
  const [clientsRef] = useState(firestore().collection("clients"));
  const {data: clients} = useFirebaseCollection(clientsRef, []);

  const [display, setDisplay] = useState([]);
  const [filters, setFilters] = useState({status: "in_progress"});

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [client, setClient] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    let _data = [...data];
    if (filters.status) _data = _data.filter(item => item.status === filters.status);
    setDisplay(_data);
  }, [data, filters])

  const _onSubmit = async () => {
    await create({
      name,
      client: client ? client.value : null,
      status: status ? status.value : null,
    })
    _onClose();
  }
  const _onDelete = async (uid) => {
    try {
      if (!window.confirm("Voulez-vous vraiment supprimer ce projet ?")) return;
      setLoading(true);
      await firestore().collection("projects").doc(uid).delete();
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  }
  useEffect(() => {console.error(error); toast.error(error)}, [error])

  const _onClose = () => {
    setIsOpen(false);
    setLoading(false);
  }

  const _renderItem = (item) => {
    let client = item.client && clients.find(c => c.uid === item.client) ? clients.find(c => c.uid === item.client) : {};

    return (
      <tr key={item.uid}>
        <th style={{color: client && client.color ? client.color : "black"}}>{item.name}</th>
        <td style={{color: client && client.color ? client.color : "black"}}>
          {client.name}
        </td>
        <td>
          <span className={`${ProjectState.find(s => s.value === item.status)?.color || "bg-gray-500"} text-white text-sm px-1.5 py-0.5 rounded-full`}>
            {ProjectState.find(s => s.value === item.status)?.label || item.status}
          </span>
        </td>
        <td className="flex">
          <Edit size={20} color="#1fb9f0" className="cursor-pointer" onClick={() => history.push(`/dashboard/projets/${item.uid}`)}/>
          <Trash size={20} color="#ff5252" className="cursor-pointer ml-2" onClick={() => _onDelete(item.uid)}/>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4">
          <h3>Projets</h3>
          <Button onClick={() => setIsOpen(true)}><Plus /></Button>
        </div>
        <div>
          <Select options={ProjectState} value={ProjectState.find(i => i.value === filters?.status)} onChange={e => setFilters({status: e?.value})} clearable />
        </div>
        <Table data={display}
               header={["Projet", "Client", "Status", "Actions"]}
               renderItem={_renderItem}
        />
        <Modal isOpen={isOpen} toggle={_onClose}>
          <h2>Créer un projet</h2>
          <div>
            <TextInput label="Nom" value={name} onChange={setName} placeholder="Name..."/>
            <Select options={clients.map(i => ({label: i.name, value: i.uid}))}
                    label="Client"
                    placeholder="Client..."
                    value={client}
                    onChange={setClient}
                    
            />
            <Select label="Status" options={ProjectState} value={status} onChange={setStatus} placeholder="Status..." />
          </div>
          <div className="flex justify-center items-center mt-4 -mb-4">
            <Button onClick={_onClose} color="secondary">Annuler</Button>
            <Button onClick={_onSubmit} loading={loading}>Créer</Button>
          </div>
        </Modal>
      </Card>
    </>
  )
}

export default Projets;