import {useState, useEffect} from "react";
import firebase from "firebase";
import {useFirebaseCollection} from "hydrogen/hooks/firebase";
import {Card, Button, Table, TextInput, Modal, Select} from "hydrogen";
import { useHistory } from "react-router-dom";
import useUI from "contexts/ui";
import { Edit } from "react-feather";

const firestore = firebase.firestore;

const Users = () => {
  const history = useHistory();
  const [usersRef] = useState(firestore().collection("users"));
  const {data, loading}= useFirebaseCollection(usersRef, []);

  const _renderItem = (item) => (
    <tr key={item.uid}>
      <td>{item.name}</td>
      <td>{item.mail}</td>
      <td>{item.role}</td>
      <td>
        <Edit onClick={() => history.push(`/dashboard/users/${item.uid}`)} className="cursor-pointer"/>
      </td>
    </tr>
  )

  return (
    <Card>
      <h3>Utilisateurs</h3>
      <Table data={data}
             header={["Nom", "Email", "Role", "Actions"]}
            renderItem={_renderItem}
            loading={loading}
      />
    </Card>
  )
}

export default Users;