import React, {useState, useEffect} from "react";
import { TextInput, Select, Button, Modal, Calendar, Card, DateTimePicker } from "hydrogen";
import firebase from "firebase";
import useUI from "contexts/ui";
import { useRef } from "react";
import { Pause, Play, Plus } from "react-feather";
import { useFirebaseDocument, useFirebaseCollection } from "hydrogen/hooks/firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore;

const TimeManager = () => {
  const [ui] = useUI();

  const [userRef] = useState(firestore().collection("users").doc(ui.user.uid));
  const {data: {slotStart, slotTitle, slotProject}} = useFirebaseDocument(userRef, {});

  const [slotsRef] = useState(firestore().collection("users").doc(ui.user.uid).collection("timeManagerSlots"));
  const {data: dbslots} = useFirebaseCollection(slotsRef, []);
  const [slots, setSlots] = useState([]);

  const [projectsRef] = useState(firestore().collection("projects"));
  const [projects, setProjects] = useState([]);

  const calendarContainerRef = useRef(null);
  const [calendarContainerHeight, setCalendarHeight] = useState(0);

  const [type, setType] = useState("play");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [eventOpen, setEventOpen] = useState(null);
  const [eventStart, setEventStart] = useState(null);
  const [eventEnd, setEventEnd] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [eventProject, setEventProject] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let subscribe = projectsRef.onSnapshot(async (s) => {
      let _data = [];

      await Promise.all(s.docs.map(async (doc) => {
        let _client = doc.data().client ? await firestore().collection("clients").doc(doc.data().client).get() : null;
        _data.push({
          ...doc.data(),
          uid: doc.id,
          client: _client ? ({uid: _client.id, ..._client.data()}) : null,
        });
      }));
      setProjects(_data);
    })
    return subscribe
  }, [])
  useEffect(() => {
    function _updateCalendarHeight() {
      if (calendarContainerRef === null || calendarContainerRef.current === null) return;
      setCalendarHeight(calendarContainerRef.current.offsetHeight);
    }
    window.addEventListener("resize", _updateCalendarHeight);
    _updateCalendarHeight();
    return () => {window.removeEventListener("resize", _updateCalendarHeight)};
  }, [calendarContainerRef])
  useEffect(() => {
    if (!dbslots) {
      setSlots([]);
      return;
    }
    const _slots = dbslots.map(slot => {
      let _project = slot.project ?? null;
      if (_project !== null) _project = projects.find(i => i.uid === _project) ?? null;

      return ({
        ...slot,
        id: slot.uid,
        start: slot.start.toDate(),
        end: slot.end.toDate(),
        title: slot.project === "sleep" ? `ZZzzz` : `${slot.project ? `${(projects.find(i => i.uid === slot.project) ?? {}).name} - `: ""} ${slot.title}`,
        description: slot.title,
        backgroundColor: slot.project === "sleep" ? "grey" : (_project && _project.client && _project.client.color ? _project.client.color : "#3788d8"),
        borderColor: slot.project === "sleep" ? "grey" : (_project && _project.client && _project.client.color ? _project.client.color : "#3788d8"),
        extendedProps: {
          project: slot.project,
          title: slot.title,
        }
      })
    })
    setSlots(_slots);
  }, [dbslots, projects]);

  const _onTypeChange = (_t) => {
    setStart(null);
    setEnd(null);
    setType(_t);
  }
  const _onStart = async () => {
    try {
      await userRef.update({slotStart: new Date()});
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
  const _onEnd = async () => {
    try {
      let _start = slotStart;
      let _title = slotTitle;
      let _project = slotProject;

      await userRef.update({slotStart: null, slotTitle: "", slotProject: null});
      await slotsRef.add({
        start: _start,
        end: new Date(),
        title: _title,
        project: _project ?? null
      });
    } catch (e) {
      console.error(e.message);
      toast.error(e.message);
    }
  }
  const _onClose = () => {
    setIsOpen(false);
    setEventOpen(null);
    setEventTitle("");
    setEventStart(null);
    setEventEnd(null);
    setEventTitle("");
    setEventProject(null);
    setLoading(false);
  }
  const _onSubmit = async () => {
    try {
      if (!start || !end) return;
      const _title = slotTitle;
      const _project = slotProject;

      await userRef.update({slotStart: null, slotTitle: "", slotProject: null});
      await slotsRef.add({
        title: _title,
        project: _project ?? null,
        start: start,
        end: end
      });
      setStart(null);
      setEnd(null);
      toast.success("Slot added");
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
  const _onEventClick = ({event}) => {
    setEventOpen(event);
    setEventTitle(event.extendedProps.title);
    setEventStart(event.start);
    setEventEnd(event.end);
    setEventProject(event.extendedProps.project);
    setIsOpen(true);
  }
  const _onDateSelect = (date) => {
    setEventOpen(null);
    setEventTitle("");
    setEventStart(date.start);
    setEventEnd(date.end);
    setEventProject(null);
    setIsOpen(true);
  }
  const _onEventEdit = async ({event}) => {
    setLoading(true);
    try {
      await firestore().collection("users").doc(ui.user.uid).collection("timeManagerSlots").doc(event.id).update({
        start: event.start,
        end: event.end,
      });
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  }
  const _onEventSubmit = async () => {
    try {
      if (eventOpen) {
        await slotsRef.doc(eventOpen.id).update({
          title: eventTitle,
          project: eventProject,
          start: eventStart,
          end: eventEnd
        });
      } else {
        await slotsRef.add({
          title: eventTitle,
          project: eventProject,
          start: eventStart,
          end: eventEnd
        });
      }
      _onClose();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
  const _onEventDelete = async () => {
    try {
      await slotsRef.doc(eventOpen.id).delete();
      _onClose();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }

  let projectsOptions = [...projects.filter(i => i.status === "in_progress").map((p) => ({label: p.name, value: p.uid})), {label: "Sieste", value: "sleep"}];

  return (
    <div>
      <div className="bg-white rounded flex items-center p-2 mb-3" style={{gap: 10}}>
        <TextInput placeholder="Titre" value={slotTitle} onChange={(e) => userRef.update({slotTitle: e})} style={{marginBottom: 0}} className="flex-1" />
        <Select options={projectsOptions}
                className="mt-3"
                placeholder="Project"
                value={projectsOptions.find((p) => p.value === slotProject)}
                onChange={(e) => userRef.update({slotProject: e ? e.value : null})}
                clearable
        />
        {type === "play" ? 
        <>
          {slotStart ? <Pause size={28} color="white" className="bg-gray-400 rounded-full cursor-pointer" onClick={_onEnd} />
          : <Play size={28} color="white" className="bg-gray-400 rounded-full cursor-pointer" onClick={_onStart} style={{paddingLeft: 4}} />}
        </>
        : type === "add" ? 
          <>
            <DateTimePicker value={start} onChange={setStart} className="mt-2" placeholder="Start..." />
            <DateTimePicker value={end} onChange={setEnd} className="mt-2" placeholder="End..." />
            <Plus size={28} color="white" className="bg-gray-400 rounded-full cursor-pointer" onClick={_onSubmit}/>
          </>
        : null}
        <div className="flex flex-col bg-gray-300 rounded-full justify-center items-center">
          <div className={`p-0.5 rounded-full cursor-pointer ${type === "play" ? "bg-gray-600" : ""} flex justify-center items-center`}
               onClick={() => _onTypeChange("play")} style={{marginBottom: 0, height: 18}}>
            <Play size={14} color="white" style={{marginLeft: 1}} />
          </div>
          <div className={`p-0.5 rounded-full cursor-pointer ${type === "add" ? "bg-gray-600" : ""} flex justify-center items-center`}
               onClick={() => _onTypeChange("add")} style={{marginBottom: 0, height: 18}}>
            <Plus size={14} color="white" />
          </div>
        </div>
      </div>
      <Card style={{height: "calc(100vh - 160px)"}} ref={calendarContainerRef}>
        <Calendar
          height={calendarContainerHeight - 48}
          events={slots}
          onDateSelect={_onDateSelect}
          onEventClick={_onEventClick}
          onDrop={_onEventEdit}
          onResize={_onEventEdit}
        />
      </Card>
      <Modal isOpen={isOpen} toggle={_onClose}>
        <div className="mb-4"><h2>{eventOpen ? "Modifier" : "Créer"} un slot</h2></div>
        <div>
          <TextInput placeholder="Titre" value={eventTitle} onChange={(e) => setEventTitle(e)} style={{marginBottom: 0}} className="flex-1" label="Titre" />
          <div className="flex flex-wrap" style={{gap: 10}}>
            <DateTimePicker value={eventStart} onChange={setEventStart} className="mt-2 flex-1" placeholder="Start..." label="Start" />
            <DateTimePicker value={eventEnd} onChange={setEventEnd} className="mt-2 flex-1" placeholder="End..." label="End"/>
          </div>
          <Select options={projectsOptions}
                  label="Project"
                  placeholder="Project"
                  value={projectsOptions.find((p) => p.value === eventProject)}
                  onChange={(e) => setEventProject(e ? e.value : null)}
                  clearable
          />
        </div>
        <div className="-mb-4 mt-4 flex justify-center items-center">
          <Button color="secondary" onClick={_onClose}>Annuler</Button>
          {eventOpen ? 
            <Button color="danger" onClick={_onEventDelete}>Supprimer</Button>
          : null}
          <Button color="primary" onClick={_onEventSubmit}>Enregistrer</Button>
        </div>
      </Modal>
    </div>
  )
}

export default TimeManager;