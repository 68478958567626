import React, {useState, useEffect} from 'react';
import {Tabs, Table, Card, Spinner, Button, Modal, NumInput, TextInput, FileUploader, Select} from "hydrogen";
import {useHistory} from "react-router-dom";
import firebase from "firebase";
import { useFirebaseCollection } from 'hydrogen/hooks/firebase';
import {Download, Edit, Plus} from "react-feather";
import { toast } from 'react-toastify';
import ProjectSelect from 'components/ProjectSelect';
import moment from 'moment';

const firestore = firebase.firestore;

const statesOptions = [
  {label: "En attente de signature", value: "pendingSignature", color: "bg-yellow-500"},
  {label: "En attente d'envoi"     , value: "pendingSending", color: "bg-yellow-500"},
  {label: "Signé"                  , value: "signed"        , color: "bg-green-500"},
  {label: "Annulé"                 , value: "canceled"      , color: "bg-red-500"},
  {label: "Refusé"                 , value: "refused"       , color: "bg-red-500"},
  {label: "Invalide"               , value: "invalid"       , color: "bg-red-500"},
]

const Devis = () => {
  const history = useHistory();
  const [devisRef] = useState(firestore().collection("devis"));
  const {data: devisData, loading: devisLoading} = useFirebaseCollection(devisRef, []);

  const [projectsRef] = useState(firestore().collection("projects"));
  const {data: projectsData} = useFirebaseCollection(projectsRef, []);

  const [isOpen, setIsOpen] = useState(false);
  const [devisOpen, setDevisOpen] = useState(null);
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [project, setProject] = useState(null);
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("pendingSending");

  useEffect(() => {
    if (!devisOpen) {
      setId(0);
      setName("");
      setProject(null);
      setFile(null);
      setStatus("pendingSending");
    } else {
      setId(devisOpen.id);
      setName(devisOpen.name);
      setProject(devisOpen.project);
      setFile(devisOpen.file);
      setStatus(devisOpen.status);
      setIsOpen(true);
    }
  }, [devisOpen])
  const _onClose = () => {
    setIsOpen(false);
    setId(0);
    setName("");
    setProject(null);
    setFile(null);
    setDevisOpen(null);
  }
  const _onSubmit = async () => {
    setLoading(true);
    try {
      if (devisOpen) {
        await firestore().collection("devis").doc(devisOpen.uid).update({
          id,
          name: name ?? null,
          project: project ?? null,
          file: file ?? null,
          status,
        })
      } else {
        await firestore().collection("devis").add({
          id,
          name: name ?? null,
          project: project ?? null,
          file: file ?? null,
          status,
          createdAt: new Date(),
        })
      }
      _onClose();

    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  }
  const _onRemove = async () => {
    try {
      if (!window.confirm("Êtes-vous sûr de vouloir supprimer ce devis ?")) return;
      await firestore().collection("devis").doc(devisOpen.uid).delete();
      _onClose();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
  const _renderItem = (item) => (
    <tr key={item.uid}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>
        {projectsData?.find(p => p.uid === item.project)?.name}
      </td>
      <td>{moment(item.createdAt.toDate()).format("DD/MM/YYYY")}</td>
      <td>
        <span className={`text-sm text-white ${statesOptions.find(i => i.value === item.status).color} rounded-full p-1`}>
          {statesOptions.find(i => i.value === item.status).label}
        </span>
      </td>
      <td>
        {item.file ? <a href={item.file} target="_blank" >
          <Download />
        </a>
        : null}
      </td>
      <td>
        <Edit className="cursor-pointer" onClick={() => setDevisOpen(item)}/>
      </td>
    </tr>
  )

  return (
    <>
      <Tabs
        activeTab={0}
        tabs={[{label: "Devis", route: "/dashboard/compta/devis"}, {label: "Factures", route: "/dashboard/compta/factures"}]}
        onTabChange={(id, tab) => history.push(`${tab.route}`)}
      />
      <div className="flex justify-end">
        <Button color="primary" onClick={() => setIsOpen(true)}><Plus /></Button>
      </div>
      <Table 
        loading={devisLoading}
        data={devisData.sort((a, b) => b.id - a.id)}
        header={["ID", "Nom", "Projet", "Date", "Status", "Fichier", "Action"]}
        renderItem={_renderItem}
      />

      <Modal isOpen={isOpen} toggle={_onClose}>
        <div className="mb-4"><h2>Créer un devis</h2></div>
        <div>
          <NumInput label="ID" value={id} onChange={setId} />
          <TextInput label="Nom" value={name} onChange={setName} />
          <FileUploader label="Fichier" value={file} onChange={setFile} />
          <ProjectSelect label="Projet" value={project} onChange={setProject} withArchived />
          <Select label="Status" value={statesOptions.find(i => i.value === status)} onChange={e => setStatus(e.value)} options={statesOptions} />
        </div>
        <div className="-mb-4 mt-4 flex justify-center items-center">
          <Button color="secondary" onClick={_onClose}>Annuler</Button>
          <Button color="primary" onClick={_onSubmit} loading={loading}>{devisOpen ? "Enregistrer" : "Ajouter"}</Button>
          {devisOpen ? <Button color="danger" onClick={_onRemove} >Supprimer</Button> : null}
        </div>
      </Modal>

    </>
  );
}

export default Devis;