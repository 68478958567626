import React, {useState, useEffect} from 'react';
import {Card, Tabs, Spinner, Button, Select, TextInput, TextArea} from "hydrogen";
import { useHistory, useParams } from 'react-router-dom';
import useRouterConfig from "routes";
import firebase from "firebase";
import { useFirebaseDocumentOnce, useFirebaseCollectionOnce } from 'hydrogen/hooks/firebase';
import { toast } from 'react-toastify';
import { ProjectState } from 'enums';

const firestore = firebase.firestore;

const Project = () => {
  const history = useHistory();
  const {routes} = useRouterConfig();
  const {id} = useParams();
  const [projetRef, setProjetRef] = useState(firestore().collection("projects").doc(id));
  const {data, loading, update} = useFirebaseDocumentOnce(projetRef, {});

  const [clientsRef] = useState(firestore().collection("clients"));
  const {data: clients, loading: clientsLoading} = useFirebaseCollectionOnce(clientsRef, []);
  const [clientsOptions, setClientsOptions] = useState([]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(null);
  const [client, setClient] = useState(null);
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {setProjetRef(firestore().collection("projects").doc(id))}, [id]);
  useEffect(() => {setClientsOptions(clients.map(c => ({label: c.name, value: c.uid})))}, [clients]);
  useEffect(() => {
    if (loading) return;

    if (data) {
      setName(data.name);
      setDescription(data.description);
      setStatus(ProjectState.find(item => item.value === data.status));
      setClient(clientsOptions.find(item => item.value === data.client));
    }
  } , [data, clientsOptions, loading])

  const _onSubmit = async () => {
    try {
      setFormLoading(true);
      await projetRef.set({
        name: name ?? "",
        description: description ?? "",
        status: status ? status.value : null,
        client: client ? client.value : null,
        updatedAt : new Date()
      });
      toast.success("Projet updated");
    } catch (e) {
      console.error(e.message);
      toast.error("Une erreur est survenue");
    }
    setFormLoading(false);
  }

  return (
    <Card>
      <Tabs
        activeTab={0}
        onTabChange={(id, tab) => {console.log(tab.route); history.push(`${tab.route}`)}}
        tabs={routes.filter(r => r.route.startsWith("projets/:id")).map(r => ({label: r.name, route: `/dashboard/${r.route}`.replace(":id", id)}))}
      />
      {loading ? <Spinner /> :
        <div>
          <div className="flex flex-wrap" style={{gap: 10}}>
            <TextInput label="Titre" value={name} onChange={setName} className="flex-1" />
            <Select label="Status" value={status} onChange={setStatus} className="flex-1"
                    options={ProjectState} />
            <Select label="Client" value={client} onChange={setClient} className="flex-1" options={clientsOptions} clearable />
          </div>
          <TextArea label="Description" value={description} onChange={setDescription} />
          <div className="flex justify-center mt-4 -mb-4">
            <Button onClick={_onSubmit} loading={formLoading}>Sauvegarder</Button>
          </div>
        </div>
      }
    </Card>
  );
}

export default Project;