import React, { useEffect, useState } from 'react';
import firebase from "firebase";
import {Card} from "hydrogen";
import useUI from 'contexts/ui';
import moment from "moment";
import useTimeManagerReport from 'hooks/useTimeManagerReport';

const firestore = firebase.firestore;

const TimeManagerTextReport = () => {
  const [ui] = useUI();

  const [startOfDay] = useState(moment().startOf("day").toDate());
  const [endOfDay] = useState(moment().endOf("day").toDate());
  const [startOfWeek] = useState(moment().startOf("week").toDate());
  const [endOfWeek] = useState(moment().endOf("week").toDate());

  const {report: dayReport, loading: dayReportLoading} = useTimeManagerReport(startOfDay, endOfDay);
  const {report: weekReport, loading: weekReportLoading} = useTimeManagerReport(startOfWeek, endOfWeek);

  const _formatDuration = (duration) => 
    `${duration[0]}:${duration[1] < 10 ? "0" + duration[1] : duration[1]}:${duration[2] < 10 ? "0" + duration[2] : duration[2]}`;

  return (
    <Card>
      <div className="flex justify-center items-start" style={{gap: 15}}>
        <div className="flex-1 border-r border-black">
          <h3 className="text-center mb-2">Day report</h3>
          {!dayReportLoading && dayReport.filter(i => i.uid !== "others" && i.uid !== "sleep").filter(i => i.uid !== "Total").map(row => (
            <div key={row.uid} className="flex justify-between items-center py-4 px-4 mx-4 border-b border-gray-300" style={{gap: 10}}>
              <span className="text-xl font-semibold">{row.project ? row.project.name : ""}</span>
              <span className="text-xl">{_formatDuration(row.duration)}</span>
            </div>
          ))}
          {!dayReportLoading && <div className="flex justify-between items-center py-4 px-4 mx-4 border-b border-gray-300" style={{gap: 10}}>
            <span className="text-xl font-semibold">Autres</span>
            <span className="text-xl">{_formatDuration(dayReport[0].duration)}</span>
          </div>}
          {!dayReportLoading && <div className="flex justify-between items-center py-4 px-4 mx-4 border-b border-gray-300" style={{gap: 10}}>
            <span className="text-xl font-semibold">Total</span>
            <span className="text-xl">{_formatDuration(dayReport[dayReport.length - 1].duration)}</span>
          </div>}
        </div>
        <div className="flex-1">
          <h3 className="text-center mb-2">Week report</h3>
          {!weekReportLoading && weekReport.filter(i => i.uid !== "others" && i.uid !== "sleep").filter(i => i.uid !== "Total").map(row => (
            <div key={row.uid} className="flex justify-between items-center py-4 px-4 mx-4 border-b border-gray-300" style={{gap: 10}}>
              <span className="text-xl font-semibold">{row.project ? row.project.name : ""}</span>
              <span className="text-xl">{_formatDuration(row.duration)}</span>
            </div>
          ))}
          {!weekReportLoading && <div className="flex justify-between items-center py-4 px-4 mx-4 border-b border-gray-300" style={{gap: 10}}>
            <span className="text-xl font-semibold">Autres</span>
            <span className="text-xl">{_formatDuration(weekReport[0].duration)}</span>
          </div>}
          {!weekReportLoading && <div className="flex justify-between items-center py-4 px-4 mx-4 border-b border-gray-300" style={{gap: 10}}>
            <span className="text-xl font-semibold">Total</span>
            <span className="text-xl">{_formatDuration(weekReport[weekReport.length - 1].duration)}</span>
          </div>}
        </div>
      </div>
    </Card>
  );
}

export default TimeManagerTextReport;