import React, {useState, useEffect} from "react";
import {Card} from "hydrogen";
import firebase from "firebase";
import { useFirebaseDocumentOnce, useFirebaseCollectionOnce } from "hydrogen/hooks/firebase";
import useUI from "contexts/ui";
import moment from "moment";
import {ClientState} from "enums";

const firestore = firebase.firestore;

const Overview = () => {
  const [ui] = useUI();
  const [clientRef] = useState(firestore().collection("clients").doc(ui.user.client));
  const [projectsRef] = useState(firestore().collection("projects").where("client", "==", ui.user.client));

  const {data: client} = useFirebaseDocumentOnce(clientRef, {});
  const {data: projects} = useFirebaseCollectionOnce(projectsRef, []);

  console.log(projects);

  return (
    <div>
      <h1>Overview</h1>
      <div className="flex" style={{gap: 10}}>
        <Card style={{flex: 1}}>
          <h4 className="mb-2">Mes projets</h4>
          {projects?.map((project) => (
            <div key={project.uid}>
              <h6>{project.name}</h6>
            </div>
          ))}
        </Card>
        <Card style={{flex: 2}}>
          <div className="flex justify-between items-center">
            <h4>Mon compte client</h4>
            <span className={`${ClientState.find(i => i.value === client.status)?.color} rounded text-white px-2 py-0.5`}>
              {client.status}
            </span>
          </div>
          <p>Société: {client.name}</p>
          {client.createdAt ? <p>Créé le {moment(client.createdAt.toDate()).format("L")}</p> : null}
        </Card>
      </div>
    </div>
  );
}

export default Overview;