import React, {useState, useEffect, useRef} from "react";
import firebase from "firebase";
import {Card, Calendar, TextInput, DateTimePicker, DatePicker, TimePicker, Modal, Button, ColorPicker} from "hydrogen";
import useUI from "contexts/ui";
import { useFirebaseCollection } from "hydrogen/hooks/firebase";
import { toast } from "react-toastify";
import ProjectSelect from "components/ProjectSelect";

const firestore = firebase.firestore;

const Agenda = () => {
  const [ui] = useUI();
  const calendarContainerRef = useRef(null);
  const [calendarCollectionRef] = useState(firestore().collection("calendar"));
  const {data} = useFirebaseCollection(calendarCollectionRef, []);
  const [calendarContainerHeight, setCalendarHeight] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [eventOpen, setEventOpen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("#3788d8");
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [project, setProject] = useState(null);

  //responsive
  useEffect(() => {
    function _updateCalendarHeight() {
      if (calendarContainerRef === null || calendarContainerRef.current === null) return;
      setCalendarHeight(calendarContainerRef.current.offsetHeight);
    }
    window.addEventListener("resize", _updateCalendarHeight);
    _updateCalendarHeight();
    return () => window.removeEventListener("resize", _updateCalendarHeight);
  }, [calendarContainerRef])

  const _onClose = () => {
    setIsOpen(false);
    setEventOpen(null);
    setTitle("");
    setStart(null);
    setEnd(null);
    setColor("#3788d8");
    setProject(null)
  }
  const _onSubmit = async () => {
    setLoading(true);
    try {
      if (eventOpen) {
        await calendarCollectionRef.doc(eventOpen.id).update({
          title,
          start,
          end,
          color,
          project,
          updatedAt: new Date(),
        });
      } else {
        await calendarCollectionRef.add({
          title,
          start,
          end,
          color,
          project,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
      _onClose();
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    setLoading(false);
  }
  const _eventClick = ({event}) => {
    console.log(event);
    setEventOpen(event);
    setTitle(event.title);
    setStart(event.start);
    setEnd(event.end);
    setIsOpen(true);
    setColor(event.backgroundColor);
    setProject(event.extendedProps.project);
  }
  const _eventRemove = async () => {
    try {
      await firestore().collection("calendar").doc(eventOpen.id).delete();
      _onClose();
    } catch (e) {
      console.error(e);
      toast.error("Erreur lors de la suppression de l'événement");
    }
  }
  const _eventEdit = ({event}) => {
    try {
      firestore().collection("calendar").doc(event.id).update({
        start: event.start,
        end: event.end
      });
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  }
  const _dateSelect = (date) => {
    setIsOpen(true);
    setStart(date.start);
    setEnd(date.end);
  }

  console.log(project);

  return (
    <div>
      <Card ref={calendarContainerRef} className="" style={{height: "calc(100vh - 100px)"}}>
        <Calendar
          height={calendarContainerHeight - 50}
          events={data.map(e => ({
            ...e,
            id: e.uid,
            start: e.start.toDate(),
            end: e.end.toDate(),
            backgroundColor: e.color,
          }))}
          onDateSelect={_dateSelect}
          onEventClick={_eventClick}
          onDrop={_eventEdit}
          onResize={_eventEdit}
        />
      </Card>
      <Modal isOpen={isOpen} toggle={_onClose}>
        <div className="mb-4"><h2>Créer un évènement</h2></div>
        <div>
          <div className="flex" style={{gap: 10}}>
            <TextInput className="flex-1" label="Titre" placeholder="Titre..." value={title} onChange={setTitle} onEnterPressed={_onSubmit} />
            <ColorPicker className="mt-7" value={color} onChange={setColor} />
          </div>
          <div className="flex flex-wrap" style={{gap: 10}}>
            <DateTimePicker className="flex-1" label="Début" value={start} onChange={setStart} />
            <DateTimePicker className="flex-1" label="Fin" value={end} onChange={setEnd} />
          </div>
          <div>
            <ProjectSelect className="mt-4" label="Projet" value={project} onChange={setProject} clearable />
          </div>
        </div>
        <div className="-mb-4 mt-4 flex justify-center items-center">
          <Button color="secondary" onClick={_onClose}>Annuler</Button>
          <Button color="primary" onClick={_onSubmit} loading={loading}>Sauvegarder</Button>
          {eventOpen && <Button color="danger" onClick={_eventRemove}>Supprimer</Button>}
        </div>
      </Modal>
    </div>
  )
}

export default Agenda;