import React, {useState, useEffect} from "react";
import {Card, Tabs, Spinner, Button, Select, TextInput, TextArea} from "hydrogen";
import { useHistory, useParams } from 'react-router-dom';
import useRouterConfig from "routes";
import firebase from "firebase";
import { useFirebaseDocumentOnce, useFirebaseCollectionOnce } from 'hydrogen/hooks/firebase';
import { toast } from 'react-toastify';
import DoubleTodoKanban from "components/DoubleTodoKanban";
import useUI from "contexts/ui";

const firestore = firebase.firestore;

const Tasks = () => {
  const history = useHistory();
  const {routes} = useRouterConfig();
  const {id} = useParams();
  const [ui] = useUI();
  const [todosRef] = useState(firestore().collection("users").doc(ui.user.uid).collection("todos").where("project", "==", id));

  return (
    <Card>
      <Tabs
        activeTab={1}
        onTabChange={(id, tab) => history.push(`${tab.route}`)}
        tabs={routes.filter(r => r.route.startsWith("projets/:id")).map(r => ({label: r.name, route: `/dashboard/${r.route}`.replace(":id", id)}))}
      />
      <DoubleTodoKanban firestoreRef={todosRef} projectId={id} />
    </Card>
  )
}

export default Tasks;